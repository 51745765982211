import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import SortingKeys from '@/config/SortingKeys'
import md5 from 'md5/md5'
import { useSessionStore } from '@/stores/useSessionStore'
import { Category } from "@/http/models/Category"
import type { City } from "@/http/models/City"

export const useMarketplaceStore = defineStore('marketplace', () => {
  const sessionStore = useSessionStore()

  const items = ref<[] | null>(null)
  const setItems = (value) => items.value = value

  const searchQuery = ref<string | null>(null)
  const setSearchQuery = (query: string | null) => searchQuery.value = query

  const currentPage = ref<number>(1)
  const setCurrentPage = (page: number) => currentPage.value = page

  const scrollPosition = ref<number>(0)
  const setScrollPosition = (y: number) => {
    scrollPosition.value = y
  }

  const sortingKey = ref<SortingKeys>(SortingKeys.Distance)
  const setSortingKey = (key: SortingKeys) => sortingKey.value = key

  const categories = ref<Category[] | null>(null)
  const setCategories = (values: []) => categories.value = values.map((c) => new Category(c))

  const filteredCategories = ref<[]>([])
  const setFilteredCategories = (value) => filteredCategories.value = value
  const toggleFilteredCategory = (value) => {
    if (filteredCategories.value.includes(value)) {
      filteredCategories.value = filteredCategories.value.filter((category) => category !== value)
    } else {
      filteredCategories.value.push(value)
    }
  }

  const selectedFiltersHash = computed(() => {
    return md5([
      sessionStore.distribution,
      JSON.stringify(sessionStore.location),
      JSON.stringify(filteredCategories.value),
      sortingKey.value,
      searchQuery.value,
    ].join('-'))
  })

  const city = ref<City | null>(null)
  const setCity = (value: City) => city.value = value

  return {
    items,
    setItems,

    setSearchQuery,
    searchQuery,

    currentPage,
    setCurrentPage,

    scrollPosition,
    setScrollPosition,

    sortingKey,
    setSortingKey,

    categories,
    setCategories,

    filteredCategories,
    setFilteredCategories,
    toggleFilteredCategory,

    selectedFiltersHash,

    city,
    setCity,
  }
})