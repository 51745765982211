import { createApp } from './main'
import { createHead } from '@unhead/vue'
import { SentryFactory } from '@/factories/SentryFactory'

// Create the app
const {app, router, pinia} = createApp()

if (!__CAPACITOR__) {
  SentryFactory.create(app, router, 'csr')
}

const clientHead = createHead()
app.use(clientHead)

if (window.__INITIAL_STATE__) {
  pinia.state.value.ssr_establishment = window.__INITIAL_STATE__.ssr_establishment
}

// Wait for the router to be ready (for async components or routes)
router.isReady().then(() => {
  // Mount the app
  app.mount('#app')
})